.formRegistration .button,
.formLogin .button{
  margin-top: 20px;
  width: 100%;
  text-align: center;
}
.buttonSecond {
  width: 100%;
  text-align: center;
}
.formRegistration .ant-form-item:last-child{
  margin-bottom: 0;
}
.secondButtons{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
h2{
  margin-top: 0;
}
.logoText{
  font-size: 24px;
    margin: 0;
    font-weight: 500;
    text-align:center;
    margin-bottom: 10px;
  font-family: "Nunito", sans-serif;
}