.containerTable {
  width:100%;
  display: table;
}
.containerCell {
  width:100%;
  height:100vh;
  display: table-cell; 
  vertical-align: middle;
}

.form {
  display: flex;
  flex-direction: column;
  width: 300px;
  padding: 20px;
  gap: 20px;
  border-radius: 20px;
  margin: auto;
  
}
