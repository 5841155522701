.roleSelectContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.roleButton {
  font-size: large;
}
.hideTitle .ant-steps-item-description,
.hideTitle .ant-steps-item-title{
  display:none;
}